import { PromoConfig } from '../../_core/types/theme/Promo.type';

const promo: PromoConfig = {
  seo: {
    tarot: {
      title: 'Your tarot reading',
      description: '',
    },
    questions: {
      title: 'Destiny quiz',
      description: '',
    },
  },
  questions: {
    bg: '/static/images/theme_icons/psychic/galaxy-bg.jpeg',
  },
  readings: {
    reading_1: 'You’ve been waiting for a significant change in your life, and though it may seem like progress is slow, '
      + 'the universe is aligning things in your favor. Trust that what you’ve been hoping for is on its way, '
      + 'and this waiting period is simply preparing you for it. ',
    reading_2: 'A familiar face from your past will reappear in your life soon. '
      + 'This reconnection will bring clarity to something that has been unresolved, '
      + 'whether it’s a past issue or simply lingering thoughts. Be open to this encounter, '
      + 'as it could offer healing or closure you didn’t even know you needed.',
    reading_3: "Lately, you've been feeling out of balance, "
      + 'but you are on the cusp of finding the harmony you seek. '
      + "Whether it's in your personal or professional life, there is a shift coming that will restore balance. "
      + 'Focus on staying grounded and being open to solutions that bring peace to your current situation.',
    reading_4: 'An unexpected opportunity will soon present itself, '
      + 'and though it may not be what you initially envisioned, '
      + 'it could lead to something even better. Trust your gut instinct on this one, as it will '
      + 'guide you toward making the right decision. This opportunity could open doors you hadn’t considered.',
    reading_5: 'There is something you’ve been holding onto—whether '
      + 'it’s an emotional burden or a situation that no longer serves you. '
      + 'It’s time to release it. Letting go will bring you a sense of relief and '
      + 'make space for something new and fulfilling to enter your life. You’ll soon feel lighter and more at peace.',
    reading_6: 'You may have noticed a shift in your energy recently, '
      + "as though you're being prepared for a new phase in your life. "
      + 'This period of transformation is laying the groundwork for something positive and significant. '
      + 'Embrace the changes, even if they feel unsettling at first, '
      + 'because they are leading you in the right direction.',
    reading_7: 'Someone close to you has been thinking about you deeply. '
      + 'You may have felt their presence or noticed subtle signs of their thoughts. '
      + 'This person is either concerned for you or has something important to share, and soon, '
      + 'they will reach out. Be open to communication—it may surprise you how much you needed it.',
    reading_8: 'You’ve felt stagnant recently, like you\'re going through the motions without progress. '
      + 'However, a small shift in perspective is all you need to reignite your motivation. '
      + 'Try looking at your situation from a new angle, and you’ll discover possibilities you hadn’t seen before. '
      + 'This change in mindset will set you back on track.',
    reading_9: 'A situation you’ve been uncertain about, whether it’s in love, work, or family, '
      + 'will soon become clearer. The confusion you’ve been feeling is temporary, '
      + 'and new information is coming that will help you understand what’s really going on. '
      + 'Trust that clarity is just around the corner.',
    reading_10: 'You’ve been holding back on expressing something that’s been on your mind for a while. '
      + 'Perhaps you’re afraid of how others will react, or you\'re unsure of how to say it. '
      + 'Now is the time to open up and speak your truth. Honest communication will bring you '
      + 'closer to those around you and bring relief.',
    reading_11: 'News that you weren’t expecting is on its way, and it may change your plans or '
      + 'open up new paths you hadn\'t considered before. Stay flexible, as this message could lead to '
      + 'exciting developments in both your personal and professional life. '
      + 'Keep an open mind to where this opportunity might take you.',
    reading_12: 'You’ve been questioning whether you\'re on the right path, '
      + 'and recent events may have made you doubt yourself. '
      + 'But know this: you are exactly where you need to be right now. '
      + 'Trust that your journey is unfolding as it should, and while things may feel uncertain, '
      + 'the lessons you\'re learning will soon reveal their purpose.',
    reading_13: 'There’s a conflict in your life, either with someone close to you or within yourself, '
      + 'that has been weighing heavily on you. Know that a resolution is near, and soon, '
      + 'you’ll have the clarity or understanding needed to move forward. '
      + 'Be patient and allow the situation to unfold naturally—it will resolve itself in time.',
    reading_14: 'You’ve been through a lot lately, but a period of healing is approaching. '
      + 'Whether it\'s emotional, physical, or mental, the time has come for you to '
      + 'focus on restoring your strength. You’ll soon feel more like yourself again, '
      + 'and this renewed energy will allow you to move forward with more confidence and peace.',
    reading_15: 'You’ve been contemplating a change in your life, '
      + 'but something has been holding you back. The signs around you suggest '
      + 'that now is the time to act. Trust your intuition—it’s guiding you '
      + 'toward something that could bring greater fulfillment. Take that step forward, and you’ll see the path unfold.',
    reading_16: 'There’s someone you haven’t thought about in a while who is going to re-enter your life. '
      + 'This reconnection could come as a surprise, but it will bring a positive shift, '
      + 'whether it’s a renewed friendship, a business opportunity, or simply a meaningful conversation. '
      + 'Be open to where this relationship could lead.',
    reading_17: 'Creative energy is stirring within you, even if you haven\'t fully recognized it yet. '
      + 'This surge of inspiration will soon lead to new ideas, projects, '
      + 'or opportunities for self-expression. Allow yourself to explore these creative urges, '
      + 'as they could unlock something exciting and rewarding in your life.',
    reading_18: 'You’ve been searching for answers, perhaps feeling lost or confused '
      + 'about a situation in your life. The clarity you seek is on its way, '
      + 'and it will come from an unexpected source. Stay open to the messages '
      + 'and signs around you, as they will guide you toward the understanding you need.',
    reading_19: 'You’ve been working hard, both on yourself and your external goals, '
      + 'and it’s natural to feel a little weary. However, you’re on the verge of a personal breakthrough. '
      + 'This is not the time to give up—just one more push will get you over the hurdle, '
      + 'and you’ll soon feel the relief and success you’ve been waiting for.',
    reading_20: 'Your efforts are finally going to pay off, '
      + 'though the results may come in a different form than you expected. '
      + 'Stay patient and keep your focus, but also remember to take care of your well-being. '
      + 'Achieving your goals is important, but your happiness and health are just as crucial. '
      + 'Balance will bring you success.',
  },
  activePromo: 'valentinesDay',
  blackFriday: {
    active: true,
    bannerTitle: "BLACK FRIDAY FLASH OFFER",
    bannerText: "<b>50% + 10% OFF</b> <br/> all psychic readings",
    bannerBgImage: '/static/images/promo/bf-bg.png',
    cta: "Get the Offer",
    dealHeroTitle: "This Black Friday, Let’s Uncover Your True Path",
    dealHeroDescription: "Discover the answers you’ve been searching for with 50% + 10% OFF",
    dealHomeHeroTitle: "Black Friday Special: <br/> 50% + 10% Off All Readings!",
    dealHomeHeroDescription: "Due to popular demand, we have extended our biggest offer ever by 2 more days — don’t miss out!",
    endDate: new Date(new Date('12/05/2024 11:00').getTime() - new Date().getTimezoneOffset() * 60000),
    startDate: new Date("11/25/2024 17:59"),
    promoBgImage: 'seeer-bf-bg.jpg',
    homeBgImage: 'seeer-home-bf-bg.jpg',
  },
  valentinesDay: {
    active: true,
    bannerTitle: "VALENTINE’S DAY SPECIAL",
    bannerText: "<b>25% OFF</b> <br/> all psychic readings",
    bannerBgImage: '/static/images/promo/valentinesDay/mystic/banner-bg.png',
    bannerBgMobileImage: '/static/images/promo/valentinesDay/mystic/banner-bg-mobile.svg',
    advisorCardBannerBgColor: 'linear-gradient(90deg, #E44487 0%, #FE5E51 100%)',
    advisorCardBannerTitle: `Valentine's Day`,
    ribbonBgColor: '#FBE0EB',
    ribbonTextColor: '#7D123F',
    ribbonStyle: 'rounded-separate',
    cta: "Get the Offer",
    dealHeroTitle: "Does your love have a future? Find answers now with 25% off.",
    dealHeroDescription: "Find clarity with astrology, tarot & numerology",
    dealHomeHeroTitle: "Marriage, love, <br class='hidden sm:flex sm:visible'/> or separation? What’s next? </br> Find out at 25% off!",
    dealHomeHeroDescription: "Get answers to your deepest questions with a psychic reading.",
    endDate: new Date(new Date('2/18/2025 00:00').getTime() - new Date().getTimezoneOffset() * 60000),
    startDate: new Date("2/4/2025 11:47"),
    promoBgImage: '/static/images/promo/valentinesDay/mystic/promo-bg.png',
    homeBgImage: '/static/images/promo/valentinesDay/mystic/home-bg.png',
  }
};

// @ts-ignore
export default promo;
